























































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { apiBaseConfig, apiBaseConfigEdit } from "@/api/setting/setting";
import MaterialSelect from "@/components/material-select/index.vue";
import AreaSelect from "@/components/area-select.vue";

@Component({
    components: {
        MaterialSelect,
        AreaSelect,
    },
})
export default class Config extends Vue {
    /** S Data **/

    // 添加商城表单数据
    form: any = {
        platform_name: "", // 账号
        platform_ico: "", // 密码
        platform_login_image: "", // 确认密码
        return_contact: "", // 退货联系人
        return_contact_mobile: "", // 退货联系人手机号
        return_province: 0, // 退货省份id
        return_city: 0, // 退货城市id
        return_district: 0, // 退货区域id
        return_address: "", // 退货详细地址
    };
    // 手机表单验证
    ruleMobile: Array<object> = [
        { required: true, message: "必填项不能为空", trigger: "blur" },
    ];

    // 地区表单验证方法
    validatorArea: Function = (
        rule: object,
        value: string,
        callback: Function
    ) => {
        if (this.form.return_province) {
            callback();
        } else {
            callback(new Error());
        }
    };
    // 表单校验
    rules = {
        platform_name: [
            { required: true, message: "请输入平台名称", trigger: "blur" },
        ],
        return_contact: [
            {
                required: true,
                message: "请输入联系人",
                validator: this.validatorArea,
                trigger: "blur",
            },
        ],
        return_contact_mobile: this.ruleMobile,
        return_district: [
            {
                required: true,
                message: "必填项不能为空",
                validator: this.validatorArea,
                trigger: "blur",
            },
        ],
        return_address: [
            { required: true, message: "必填项不能为空", trigger: "blur" },
        ],
    };

    /** E Data **/

    /** S Methods **/
    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement;
        refs.validate((valid: boolean): any => {
            if (!valid) return;
            this.handleBaseConfigEdit();
        });
    }

    // 编辑平台信息
    async handleBaseConfigEdit(): Promise<void> {
        await apiBaseConfigEdit({ ...this.form });
        this.getBaseConfigFunc();
    }

    // 获取详情
    async getBaseConfigFunc(): Promise<void> {
        const res = await apiBaseConfig();
        for (var key in res) {
            this.$set(this.form, key, res[key]);
        }
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getBaseConfigFunc();
    }
    /** E Life Cycle **/
}
